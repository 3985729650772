<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCodeList"
          :options="areaOptions"
          clearable
          filterable
          placeholder="请选择门店区域"
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCodeList"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 300px;"
          multiple
          filterable
          clearable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>

      <el-form-item label="是否启用">
        <el-select v-model="data.isOpenSeparateAccount" filterable placeholder="请选择" clearable>
          <el-option label="停用" :value="0" />
          <el-option label="启用" :value="1" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="handleAdd" v-if="data.authMenus.add">
          新增
        </el-button>
        <el-button @click="exportData" v-if="data.authMenus.export">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" border style="width: 96%; margin-left: 2%;" :emptyText="emptyTableText">
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="storeCode" label="门店编号" />
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="storeName" label="门店名称" />
      <el-table-column
        align="center"
        width="180px"
        show-overflow-tooltip
        prop="collectCaliberRemark"
        label="收取口径备注"
      />
      <el-table-column
        align="center"
        width="180px"
        show-overflow-tooltip
        prop="collectCaliberProportion"
        label="收取口径"
        ><template #default="{row}"> {{ row.collectCaliberProportion }} % </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="180px"
        show-overflow-tooltip
        prop="extensionProportion"
        label="门店市场推广服务费"
      >
        <template #default="{row}"> {{ row.extensionProportion }} % </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="180px"
        show-overflow-tooltip
        prop="equityAmountProportion"
        label="权益金比例"
      >
        <template #default="{row}"> {{ row.equityAmountProportion }} % </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="180px"
        show-overflow-tooltip
        prop="isOpenSeparateAccount"
        label="是否启用"
      />
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="startDate" label="开始时间">
        <template #default="{row}"> {{ row.isOpenSeparateAccount == '是' ? row.startDate : '' }} </template>
      </el-table-column>
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="endDate" label="结束时间">
        <template #default="{row}"> {{ row.isOpenSeparateAccount == '是' ? row.endDate : '' }} </template>
      </el-table-column>
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="updateUserName" label="更新人" />
      <el-table-column align="center" width="180px" show-overflow-tooltip prop="updateTime" label="更新时间" />
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button type="text" @click="handleUpdate(scope.row)" v-if="data.authMenus.update">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <addOrUpdate
      v-if="dialogVisible"
      :storeList="data.storeList"
      :needUpdateInfo="needUpdateInfo"
      @handleCloseDialog="handleCloseOrOpenDialog"
    ></addOrUpdate>
  </div>
</template>

<script setup>
import API from '../../service/api'
import { ElMessage } from 'element-plus'
import { reactive, onMounted, ref, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'
import addOrUpdate from './add-or-update.vue'

const needUpdateInfo = ref({})
const dialogVisible = ref(false)

/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = ({ update }) => {
  dialogVisible.value = false
  if (update) {
    searchLists()
  }
}

const handleAdd = () => {
  needUpdateInfo.value = {
    time: [dayjs().format('YYYY-MM-DD'), '2099-12-31'],
    collectCaliberProportion: '6.00',
    extensionProportion: '0.50',
    isOpenSeparateAccount: 1,
  }
  dialogVisible.value = true
}

const handleUpdate = row => {
  dialogVisible.value = true
  needUpdateInfo.value = {
    ...row,
    time: [row.startDate, row.endDate],
    isOpenSeparateAccount: row.isOpenSeparateAccount == '是' ? 1 : 0,
  }
}

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea({ isOnlyEnable: 0 }).then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}

const props = defineProps({
  auths: {},
})
const data = reactive({
  storeCodeList: [],
  regionCodeList: [],
  isOpenSeparateAccount: '',
  areaCode: [],
  authMenus: {},
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
})

const route = useRoute()

onMounted(async () => {
  operationAuth(route, data.authMenus)
  getStores()
  getAreas()
})

const getStores = async () => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.storeCodeList = []
  data.regionCodeList = []
  data.areaCode = []
  data.tableData = []
  data.pageSize = 10
  data.pageNum = 1
  data.total = 0
  data.isOpenSeparateAccount = ''
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  searchLists()
}

const exportData = async event => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  data.fullscreenLoading = true
  let model = {}
  model.stores = data.storeCodeList
  model.regions = data.areaCode
  model.isOpenSeparateAccount = data.isOpenSeparateAccount
  data.fullscreenLoading = true
  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=STORE_EQUITY_AMOUNT_CONFIG`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

const emptyTableText = ref('请先设置查询条件，再点击查询数据')
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  let params = {
    stores: data.storeCodeList,
    regions: data.areaCode,
    isOpenSeparateAccount: data.isOpenSeparateAccount,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  params = generateParam(params)
  //占坑
  data.tableData = []
  getTableData(params, 'STORE_EQUITY_AMOUNT_CONFIG')
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData.push(...(res.data.items ?? []))
    data.total = res.data.total
    emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
  } else {
    data.tableData = []
    data.total = 0
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
</style>
